import React, { useEffect, useState } from "react";
import InputComponent from "../../../../components/Fields/InputField";
import DropDownComponent from "../../../../components/Fields/CustomDropdown";
import CheckboxComponent from "../../../../components/Fields/Checkbox";
import { Country, State } from "country-state-city";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { useDispatch } from "react-redux";
import { addCardAction, deleteCardAction } from "../../../../redux/actions/familyAction";

export default function AddNewCard({ openCard, setOpenCard,isExpire,setIsExpire ,parentId}) {
    const {
        meta,
        getCardNumberProps,
        getExpiryDateProps,
        getCardImageProps,
        getCVCProps
      } = usePaymentInputs();
    const CardOptions = [
        { name: "MasterCard", value: "mastercard" },
        { name: "Visa", value: "visa" },
        { name: "AmericianExpress", value: "americianExpress" },
        { name: "Discover", value: "discover" },
    ];
    const dispatch = useDispatch();
    const [cardDetail, setCardDetail] = useState({
        card_holder_name: "",
        card_type: "",
        card_number: "",
        expiry_month: "",
        expiry_year: "",
        card_security_code: "",
        country: [],
        state: [],
        city: "",
        address_line_1: "",
        address_line_2: "",
        zip_code: "",
        is_default: false,
    });

    const generateMonthOptions = () => {
        const monthOptions = [];
        for (let i = 1; i <= 12; i++) {
            const monthNo = i < 10 ? `0${i}` : i;
            monthOptions.push({
                name: monthNo,
                value: monthNo,
            });
        }
        return monthOptions;
    };

    const generateYearOptions = () => {
        const yearOptions = [];
        let year = new Date().getFullYear();
        for (let i = year; i <= year + 20; i++) {
            yearOptions.push({
                name: i,
                value: i,
            });
        }
        return yearOptions;
    };

    const [countryStates, setCountryStates] = useState([]);
    useEffect(() => {
        getUpdatedStates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardDetail?.country]);

    const getUpdatedStates = () => {
        let res = State.getStatesOfCountry(cardDetail?.country);
        setCountryStates(res);
    };
    const [err, setErr] = useState({});

    const validateForm = () => {
        let errors = {};
        if (!cardDetail?.card_type) {
            errors.card_type = "Card Type is required";
        }
        if (meta.erroredInputs.cardNumber) {
            errors.card_number = meta.erroredInputs.cardNumber;
          }
        if (!cardDetail?.expiry_month) {
            errors.expiryDate = "Expiry Month is required";
        } else if (!cardDetail?.expiry_year) {
            errors.expiryDate = "Expiry Year is required";
        } else if (!isExpirationDateValid) {
            errors.expiryDate = "The expiration date is invalid. Please enter a valid expiration date.";
        }
        if (meta.erroredInputs.cvc) {
            errors.card_security_code = meta.erroredInputs.cvc
          }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };
    const handleNumberChange =(e)=>{
        setCardDetail({ ...cardDetail, card_number: e.target.value });
          }
          const handleCsvChange =(e)=>{
            setCardDetail({ ...cardDetail, card_security_code: e.target.value });
            
              }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setCardDetail({ ...cardDetail, [name]: value });
        setErr({ ...err, [name]: "" });
    };

    const handleChange1 = (e) => {
        const { name } = e.target;
        setCardDetail({ ...cardDetail, [name]: e.value });
        setErr({ ...err, card_type: "", expiryDate: "" });
        // setErr({ ...err, ["expiryDate"]: "" });
    };
    const [countries] = useState(Country.getAllCountries());

    const handleCheck = (name) => {
        setCardDetail({ ...cardDetail, [name]: !cardDetail[name] });
    };

    const handleSave = (e) => {
        e.preventDefault();

        const form = validateForm();

        if (form) {
            setOpenCard(null);
            if(isExpire && parentId){
                dispatch(deleteCardAction(parentId,isExpire,()=>{
                    dispatch(addCardAction(cardDetail, setOpenCard,openCard));
                    setIsExpire(null)
                }));
            }else{
            dispatch(addCardAction(cardDetail, setOpenCard,openCard))
            }
            // setSelectedItems((prev) => [...prev, cardDetail]);
            setCardDetail({
                card_holder_name: "",
                card_type: "",
                card_number: "",
                expiry_month: "",
                expiry_year: "",
                card_security_code: "",
                country: [],
                state: [],
                city: "",
                address_line_1: "",
                address2: "",
                zip_code: "",
                is_default: false,
            });

        }
    };
    // Card Validations

    const month = cardDetail?.expiry_month;
    const year = cardDetail?.expiry_year;

    const isExpirationDateValid = validateExpirationDate({
        month,
        year,
    });

    function validateExpirationDate() {
        const month = parseInt(cardDetail?.expiry_month);
        const year = parseInt(cardDetail?.expiry_year);
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        const currentMonth = currentDate.getMonth() + 1; // Add 1 because getMonth() returns 0-based index
        return year > currentYear || (year === currentYear && month >= currentMonth);
    }

    const isCardValid = validateCardNumber(cardDetail?.card_number);

    function validateCardNumber(cardNumber) {
        const cardNumberWithoutSpaces = cardNumber?.replace(/ /g, "");
        let sum = 0;
        let doubleUp = false;
        for (let i = cardNumberWithoutSpaces?.length - 1; i >= 0; i--) {
            let digit = parseInt(cardNumberWithoutSpaces?.charAt(i));
            if (doubleUp) {
                digit *= 2;
                if (digit > 9) {
                    digit -= 9;
                }
            }
            sum += digit;
            doubleUp = !doubleUp;
        }
        return sum % 10 === 0;
    }

    return (
        <div className="p-3">
            <InputComponent
                label="Card Holder Name"
                extraClassName="mb-3 py-2"
                placeholder=""
                required
                name="card_holder_name"
                value={cardDetail?.card_holder_name}
                onChange={handleChange}
            />
            <DropDownComponent
                label=" Card Type"
                options={CardOptions}
                extraClassName="mb-3 py-2"
                placeholder=""
                required
                optionLabel="name"
                name="card_type"
                value={cardDetail?.card_type}
                onChange={handleChange1}
                errorMessage={err?.card_type}
            />
               <div className="col-12 md:col-12 mb-3">
                        <div className="relative">
                            <svg {...getCardImageProps({ images })} className="absolute card-svg" />
                            <input
                                className="w-full p-3 border-round-lg border-1 border-700"
                                name="card_number"
                                {...getCardNumberProps({ onChange: handleNumberChange })}
                                value={cardDetail?.card_number}
                            />
                        </div>
                        <div className="text-danger text-sm">{err?.card_number}</div>
                    </div>
            <div className="grid flex mb-2 align-items-center">
                <div className="md:col-12">
                    <p className=" font-bold">Expiry Date (MM/YY)</p>
                </div>
                <DropDownComponent size="md:col-4 py-2ol-3" placeholder="" required
                    optionLabel="name"
                    options={generateMonthOptions()}
                    name="expiry_month"
                    value={cardDetail?.expiry_month}
                    onChange={handleChange1}

                />
                <DropDownComponent size="md:col-4 py-2ol-3" placeholder="" required options={generateYearOptions()}
                    optionLabel="name"
                    name="expiry_year"
                    value={cardDetail?.expiry_year}
                    onChange={handleChange1} />
            </div>

            {err && <div className="text-danger text-sm open-sans">{err?.expiryDate}</div>}
            <div className="col-12 md:col-12 mb-3">
                  <div className="relative">
               <input className="w-full p-3 border-round-lg border-1 border-700" {...getCVCProps({onChange:handleCsvChange})} value={cardDetail?.card_security_code} placeholder="CVC Code"  />
               </div>
               <div className="text-danger text-sm">{err?.card_security_code}</div>
               </div>
            <DropDownComponent label="Country" extraClassName="mb-3 py-2" required name="country"
                optionLabel="name"
                options={
                    countries?.length &&
                    countries?.map((country, i) => {
                        return { name: country.name, value: country.isoCode };
                    })
                }
                value={cardDetail?.country}
                onChange={handleChange1} />
            <DropDownComponent label="State/Province" extraClassName="mb-3 py-2" required name="state"
                optionLabel="name"
                options={countryStates?.map((state, i) => {
                    return { name: state.name, value: state.isoCode };
                })}
                value={cardDetail?.state}
                onChange={handleChange1} />
            <InputComponent label="City" extraClassName="mb-3 py-2" required name="city" value={cardDetail?.city} onChange={handleChange} />
            <InputComponent label="Address Line 1" extraClassName="mb-3 py-2" required name="address_line_1"
                value={cardDetail?.address_line_1}
                onChange={handleChange} />
            <InputComponent label="Address Line 2" extraClassName="mb-3 py-2" required name="address_line_2"
                value={cardDetail?.address_line_2}
                onChange={handleChange} />
            <InputComponent label="Zip/Postal Code" extraClassName="mb-3 py-2" required name="zip_code"
                value={cardDetail?.zip_code}
                onChange={handleChange}
                keyfilter="int" />
            <div className="flex mb-3">
                {/* <p className="font-bold">Auto-Pay</p> */}
                <CheckboxComponent label= "Mark card as default" extraClassName="ml-2" name="is_default"
                    checked={cardDetail?.is_default}
                    onChange={() => handleCheck("is_default")} />
            </div>

            <div className="flex justify-content-end">
                <button className="open-sans font-semibold text-lg bg-main text-900  border-none border-round-lg p-2 px-4" onClick={handleSave}>
                    Save
                </button>
                <button
                    className="open-sans font-semibold text-lg text-900 white-smoke p-2 px-4 ml-2 border-none border-round-lg"
                    onClick={() => {
                        setOpenCard(null);
                        setCardDetail({});
                        setErr({});
                        setIsExpire(null)
                    }}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
}
