import Constants from "../../services/constant";
import { loadStateFn } from "../../utils/localStorage";
import { api } from "../../services/api";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import types from "../types";
import { removeSpaces } from "../../utils/commonFunctions";
import { showToast } from "../../utils/showToast";

export const getFamilyAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.GET_FAMILY, {}, loadStateFn("token"));

    if (res.success) {
        dispatch({
            type: types.FETCH_FAMILIES,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const getParentCard = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.PARENT_CARD+id, {}, loadStateFn("token"));

    if (res.success) {
        dispatch({
            type: types.PARENT_CARD,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const addCardAction = (cardDetail, setOpenCard,openCard) => async (dispatch) => {
    dispatch(showLoaderAction());
  
    const payload = {
      ...cardDetail,
      name: cardDetail?.card_holder_name,
      is_default: cardDetail?.is_default ? 1 : 0,
      card_number: removeSpaces(cardDetail?.card_number)
    };
  
    const res = await api(
      "post",
      Constants.END_POINT.ADD_CARD+openCard,
      payload
    );
  
    if (res.success) {
      showToast([res.message], "success");
      setOpenCard(false);
      dispatch(getParentCard(openCard))
    } else {
      showToast([res.message], "error");
    }
  
    dispatch(hideLoaderAction());
  }

export const getFamilyDropDownAction = (status) => async (dispatch, getState) => {
    let { family } = getState();

    if (!family.familyDropdown.length) {
        dispatch(showLoaderAction());
    }
    const params = {
        account_status: status,
    };

    const res = await api("get", Constants.END_POINT.GET_FAMILY_DROPDOWN, {}, loadStateFn("token"), params);

    if (res.success) {
        dispatch({
            type: types.FETCH_FAMILIES_DROPDOWN,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const deleteCardAction = (parentId,cardId,next) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.DELETE_CARD + parentId+"/"+cardId, {});

    if (res.success) {
        showToast([res.message], "success");
        next();
    } else {
        showToast([res.message], "error");
    }

    dispatch(hideLoaderAction());
};
